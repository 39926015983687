// @flow
import React from 'react'

import {analytics} from '../../common/analytics'

import CloseIcon from '../Svgs/CloseIcon'
import Button from '../Button'

import styles from './BoilerRoomXMap.less'

type CollectiveType = {
  title: string,
  text: string,
  link: string,
  top: string,
  left: string,
  buttonText: string,
  image: string,
  blinking?: boolean,
}

const collectives: Array<CollectiveType> = [
  {
    title: 'SPIELRAUM',
    text: 'A leading light in Amsterdam’s queer nightlife scene, SPIELRAUM is revolutionising queer spaces whilst pushing for progression within the industry.',
    link: 'https://boilerroom.tv/session/boiler-room-festival-amsterdam-x',
    buttonText: 'WATCH',
    top: '19%',
    left: '7.4%',
    image: 'spielraum.jpg',
  },
  {
    title: 'The Internatiiional',
    text: 'A Seoul-based creative and party collective whose cross-disciplinary practice has touched all corners of the city’s creative scene.',
    link: 'https://boilerroom.tv/session/boiler-room-seoul-the-internatiiional',
    buttonText: 'WATCH',
    top: '31%',
    left: '41%',
    image: 'the_internatiiional.jpg',
  },
  {
    title: 'Weeeirdos',
    text: 'A Berlin-based queer platform and anything-goes event series, founded by Iraqi-American superforce Lolsnake.',
    link: 'https://boilerroom.tv/session/weeeirdos',
    buttonText: 'WATCH',
    top: '16.5%',
    left: '9.3%',
    image: 'weeeirdos.jpg',
  },
  {
    title: 'Club Quarantine',
    text: 'The original Toronto-based queer online dance party that began hosting URL parties in early 2020 during the COVID-19 pandemic.',
    link: 'https://boilerroom.tv/playlist/club-quarantine',
    buttonText: 'WATCH',
    top: '20%',
    left: '83%',
    image: 'club_quarantine.jpg',
  },
  {
    title: 'Refuge Worldwide',
    text: 'A Berlin-based community radio station and fundraising platform working in solidarity with grassroots and non-profit organisations.',
    link: 'https://boilerroom.tv/session/boiler-room-festival-berlin-day-3',
    buttonText: 'WATCH',
    top: '20%',
    left: '10%',
    image: 'refuge_worldwide.jpg',
  },
  {
    title: 'Regenerate',
    text: 'A Mumbai-based electronic music agency pushing global underground music to the forefront in cities across India.',
    link: 'https://boilerroom.tv/session/mumbai-2024',
    buttonText: 'GET TICKETS',
    top: '44%',
    left: '27.7%',
    image: 'regenerate.jpeg',
    blinking: true,
  },
  {
    title: 'Normie Corp',
    text: 'A pillar of Vancouver\'s queer nightlife scene, Normie Corp is a platform for local & international QTBIPOC. By the community, for the community.',
    link: 'https://boilerroom.tv/session/vancouver-saturday',
    buttonText: 'GET TICKETS',
    top: '19%',
    left: '71.7%',
    image: 'normie corp.jpg',
    blinking: true,
  },
]

export default () => {
  // $FlowFixMe
  const [openCollectiveIndex, setCollectiveIndex] = React.useState(null)
  const openCollective = collectives[openCollectiveIndex]
  const closeModal = () => setCollectiveIndex(null)
  // $FlowFixMe
  const scrollRef = React.useRef(null)

  const openCollection = index => {
    setCollectiveIndex(index)
    analytics.track('boilerroomx-map-open-pin', {}, ['ga'])
  }

  // $FlowFixMe
  React.useEffect(() => {
    const scrollElement = scrollRef.current
    if (scrollElement) {
      scrollElement.scrollTo({
        left: scrollElement.scrollWidth / 5,
      })
    }
  }, [])

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ScrollWrapper} ref={scrollRef}>
        <div className={styles.MapPinWrapper}>
          <img src={require('./worldmap.png')} className={styles.Map} onClick={closeModal} />
          {collectives.map((collective, index) =>
            <Button
              disableDefaultStyles
              className={collective.blinking ? styles.BlinkingPin : styles.Pin}
              key={`collection_${index}`}
              style={{top: collective.top, left: collective.left}}
              onClick={() => openCollection(index)}
            />
          )}
        </div>
      </div>

      {openCollective &&
        <div className={styles.CollectiveModal} onClick={closeModal}>
          <div className={styles.CollectiveModalWrapper} onClick={e => e.stopPropagation()}>
            <CloseIcon onClick={closeModal} className={styles.CloseModal} />
            <div className={styles.CollectiveImage}>
              <img src={`https://misc.boilerroom.tv/${openCollective.image}`} />
            </div>
            <h4>{openCollective.title}</h4>
            <p>{openCollective.text}</p>
            <Button href={openCollective.link} text={openCollective.buttonText} />
          </div>
        </div>
      }
    </div>
  )
}
