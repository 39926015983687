// @flow
import React from 'react'

import {genericItemTypes, uiLocations} from '../../common/constants'
import type {ShowType} from '../../common/types'
import {itemize, itemizeCollection} from '../../common/itemize'

import Page from '../Page'
import Loading from '../Loading/'
import Slider from '../Slider'
import ShopSlider from '../ShopSlider'
import Card from '../Card'
import Grid, {GridItem} from '../Grid'
import Query from '../Query'
import ArrowLink from '../ArrowLink'

import GET_SHOWS from './Query'
import style from './Upcoming.less'
import {analytics} from '../../common/analytics'

export default () => <Query query={GET_SHOWS} Component={Upcoming} />

type Props = {
  currentShows: {
    shows: Array<ShowType>,
  },
  pastShows: {
    shows: Array<ShowType>,
  },
  loading: boolean,
}

export const Upcoming = (props: Props) => {
  // $FlowFixMe
  const [activeCityFilter, setActiveCityFilter] = React.useState(null)

  const {currentShows, pastShows, loading} = props

  if (loading && (!currentShows || !pastShows)) return <Loading />

  const filterCity = show => !activeCityFilter || show.city.name === activeCityFilter

  const deactivateCityFilter = () => setActiveCityFilter(null)

  const activateCityFilter = (city: string) => {
    setActiveCityFilter(city)
    analytics.track('filter-shows-by-city', {city: city})
  }

  return (
    <Page title={'Upcoming Events'} contentColumn={true} ssr={true}>
      <Grid>
        {/* City Filter */}
        <GridItem width={12}>
          <Filter
            currentShows={currentShows}
            activeCityFilter={activeCityFilter}
            activateCityFilter={activateCityFilter}
            deactivateCityFilter={deactivateCityFilter}
          />
        </GridItem>

        {/* Upcoming Show Cards */}
        {currentShows.shows.filter(filterCity).map((show, i) => (
          <GridItem responsiveWidths={{small: 12, medium: 6, large: 4, extraLarge: 4}} key={i}>
            <Card
              item={itemize(show, genericItemTypes.show)}
              uiLocation={uiLocations.upcomingPageCard}
            />
          </GridItem>
        ))}
      </Grid>

      <Slider
        title='Past Events'
        internalLink={'/events'}
        items={itemizeCollection(pastShows.shows, genericItemTypes.show)}
        cardsPerRowOverride={3}
      />

      <div className={style.UpcomingProductSlider}>
        <ShopSlider />
      </div>
    </Page>
  )
}

const Filter = ({currentShows, activeCityFilter, activateCityFilter, deactivateCityFilter}) => {
  const cities = [...new Set(currentShows.shows.map(show => show.city.name))]

  return (
    <div className={style.FiltersWrapper}>
      <div className={style.FiltersTitle}>
        <span>
          Filter by city
          <img src={require('./filter.svg')} />
        </span>
        <ArrowLink text={'FIND YOUR CITY'} link={'/cities'} uiLocation={uiLocations.findYourCityLink} />
      </div>
      <div className={style.Filters}>
        {cities.sort().map(city => (
          <div
            key={city}
            className={city === activeCityFilter ? style.ActiveFilter : style.Filter}
            onClick={() => (city === activeCityFilter ? deactivateCityFilter() : activateCityFilter(city))}
          >
            {city}
          </div>
        ))}
      </div>
    </div>
  )
}
