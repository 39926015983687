import React from 'react'
import {useQuery} from '@apollo/client'

import {uiLocations} from '../../common/constants'

import imageUrl from '../../common/imageUrl'

import Grid, {GridItem} from '../Grid'
import Link from '../Link'
import ArrowLink from '../ArrowLink'

import mapTheme from '../Location/mapTheme'

import GET_CITIES from './Query'

import styles from './CitiesGrid.less'

export default () => {
  const {data, error} = useQuery(GET_CITIES)

  if (error) return null

  const citiesWithMostShows = data && data.cities && data.cities.slice(0, 12)
  const apiKey = 'AIzaSyC0F2sOQJUqodpWzfXhzSFlxtBenaeUYPk'

  return (
    <Grid className={styles.CitiesGrid}>
      <div className={styles.CityGridHeaderContent}>
        <Link internalLink={'/cities'} uiLocation={uiLocations.citiesGrid}>
          <h3>Cities</h3>
        </Link>
        <ArrowLink text={'FIND YOUR CITY'} link={'/cities'} />
      </div>
      {citiesWithMostShows && citiesWithMostShows
        .map((city, index) => {
          let cityName = city.name.replace(' ', '+')

          if (cityName === 'Barcelona') cityName = 'Barcelona,Spain'

          return (
            <GridItem
              responsiveWidths={{small: 6, medium: 6, large: 4, extraLarge: 3}}
              gutter={true}
              key={index}
            >
              <Link
                internalLink={`/city/${city.slug}`}
                className={styles.CityButton}
                style={{
                  backgroundImage: `url(${imageUrl(`https://maps.googleapis.com/maps/api/staticmap?center=${cityName}&zoom=12&scale=4&size=2880x1580&key=${apiKey}&style=${mapTheme}`)})`,
                }}
                uiLocation={uiLocations.citiesGrid}
              >
                {city.name}
              </Link>

            </GridItem>
          )
        })
      }
    </Grid>
  )
}
