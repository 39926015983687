import moment from 'moment'

export const groupShowsByCoordinates = shows => {
  if (!shows) return []

  return shows.reduce((groupedShows, currentShow) => {
    if (!currentShow.city) return groupedShows
    const {id: cityId, latitude: lat, longitude: lng} = currentShow.city

    if (lat && lng) {
      const showsInTheSameCity = groupedShows.find(show => show.cityId === cityId)

      const isUpcoming = isUpcomingShow(currentShow)

      if (showsInTheSameCity) {
        showsInTheSameCity.shows.push({isUpcoming})
      } else {
        groupedShows.push({
          cityId,
          lat,
          lng,
          shows: [{isUpcoming}],
        })
      }
    }
    return groupedShows
  }, [])
}

export const isUpcomingShow = show => {
  if (!show.start) return true

  return moment(show.start).isAfter(moment())
}
