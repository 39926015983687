import React, {useEffect, useRef, useMemo, useCallback} from 'react'
import {useQuery} from '@apollo/client'
import * as THREE from 'three'

import isMobile from 'is-mobile'
import {hasWindow, uiLocations} from '../../common/constants'
import {groupShowsByCoordinates} from '../../common/groupShowsByCoordinates'

import Link from '../Link'
import ArrowLink from '../ArrowLink'
import PinWithHole from '../Svgs/PinWithHole'

import earthImage from './world_globe.jpg'
import styles from './Globe.less'

import GET_UPCOMING_SHOWS from './Query'

const Globe = hasWindow ? require('react-globe.gl').default : () => null

export default () => {
  const globeRef = useRef()

  const {data} = useQuery(GET_UPCOMING_SHOWS)

  const isMobileDisplay = isMobile()

  useEffect(() => {
    if (globeRef.current) {
      const globe = globeRef.current
      const controls = globe.controls()

      controls.enabled = true
      controls.autoRotate = true
      controls.autoRotateSpeed = 0.3
      controls.enableZoom = false

      globe.pointOfView({altitude: 1.4})
    }
  }, [])

  if (!data) return null

  const shows = data && data.shows && data.shows.shows
  const groupedShows = useMemo(() => groupShowsByCoordinates(shows), [shows])

  // memoize sphere geometry to reduce computation
  const sphereGeometry = useMemo(() => new THREE.SphereGeometry(0.4, 16, 16), [])

  // dynamically generate material color - useCallback to prevent re-renders unless color changes
  const getMaterial = useCallback(color => new THREE.MeshBasicMaterial({color}), [])

  const createCustomThreeObject = useCallback(layerData => {
    const locationHasUpcomingShow = layerData.shows.find(show => show.isUpcoming)
    const color = locationHasUpcomingShow ? '#FFE704' : '#fff'

    const material = getMaterial(color)

    const group = new THREE.Group()
    const sphere = new THREE.Mesh(sphereGeometry, material)
    group.add(sphere)

    if (locationHasUpcomingShow) {
      // add pulse ring effect for upcoming shows
      const initialInnerRadius = 0.4
      const initialOuterRadius = 0.6
      const ringWidth = initialOuterRadius - initialInnerRadius

      const pulseRing = new THREE.Mesh(
        new THREE.RingGeometry(initialInnerRadius, initialOuterRadius, 32),
        new THREE.MeshBasicMaterial({color: '#FFE704', transparent: true, opacity: 0.8, side: THREE.DoubleSide})
      )

      const {x, y, z} = globeRef.current.getCoords(layerData.lat, layerData.lng)
      const normalizedVector = new THREE.Vector3(x, y, z).normalize()
      pulseRing.position.copy(normalizedVector.clone().multiplyScalar(0.42))
      pulseRing.lookAt(0, 0, 0)
      group.add(pulseRing)

      const clock = new THREE.Clock()
      const duration = 1.4

      const animatePulse = () => {
        const elapsedTime = clock.getElapsedTime() % duration
        const progress = elapsedTime / duration

        const scale = 0.2 + 3.8 * progress
        const newOuterRadius = initialOuterRadius * scale
        const newInnerRadius = newOuterRadius - ringWidth

        pulseRing.geometry.dispose()
        pulseRing.geometry = new THREE.RingGeometry(newInnerRadius, newOuterRadius, 32)
        pulseRing.material.opacity = 1 - progress

        requestAnimationFrame(animatePulse)
      }

      animatePulse()
    }

    return group
  }, [sphereGeometry, getMaterial])

  const customThreeObjectUpdate = useCallback((threeObject, objectData) => {
    const altitudeOffset = -0.003
    const {x, y, z} = globeRef.current.getCoords(objectData.lat, objectData.lng, altitudeOffset)
    if (!threeObject.position.equals(new THREE.Vector3(x, y, z))) {
      threeObject.position.set(x, y, z)
    }
  }, [])

  const globeHeight = isMobileDisplay ? 1000 : 1400

  return (
    <div className={styles.Wrapper}>
      <div className={styles.HeaderWrapper}>
        <div className={styles.TextWrapper}>
          <h1>Connecting club Culture<br />To The World</h1>
        </div>
        <div className={styles.CTAWrapper}>
          <Link
            className={styles.FindAnEventButton}
            externalLink={'/upcoming'}
            uiLocation={uiLocations.globeHeroPanelCta}
          >
            <PinWithHole />
            FIND AN EVENT
          </Link>
          <ArrowLink
            className={styles.BrowseTheArchiveLink}
            text={'Browse the archive'}
            link={'/video'}
            uiLocation={uiLocations.globeHeroPanelCta}
          />
        </div>
      </div>
      <div className={styles.GlobeWrapper}>
        <div className={styles.Gradient} />
        <Globe
          ref={globeRef}
          globeImageUrl={earthImage}
          backgroundColor='#000'
          width={hasWindow && window.innerWidth}
          height={globeHeight}
          showAtmosphere={false}
          animateIn={false}
          enablePointerInteraction={false}
          customLayerData={groupedShows}
          customThreeObject={createCustomThreeObject}
          customThreeObjectUpdate={customThreeObjectUpdate}
          onGlobeReady={() => globeRef.current.pointOfView({lng: 15, lat: 22})}
        />
      </div>
    </div>
  )
}
