import {gql} from '@apollo/client'

export default gql`
  query AllShowsQuery {
    shows {
      shows {
        id
        start
        city {
          id
          latitude
          longitude
        }
      }
    }
  }
`
