// @flow
import React from 'react'
import isMobile from 'is-mobile'

import shouldDisplayChartPosition from '../../common/shouldDisplayChartPosition'
import {createImageAltText} from '../../common/createImageAltText'
import type {GenericItemType} from '../../common/types'
import {genericItemTypes} from '../../common/constants'
import striptags from 'striptags'

import IconWithText from '../IconWithText'
import Bolt from '../Svgs/Bolt'
import Link from '../Link'
import ItemInfo from '../ItemInfo'
import Background from '../Background'
import BroadcastGenres from '../BroadcastGenres'

import styles from './Tile.less'

export default ({item, className, uiLocation}: {item: GenericItemType, className?: string, uiLocation: string}) => {
  // $FlowFixMe
  const [isHovered, setIsHovered] = React.useState(false)

  if (!item) return null
  const image = item.show && item.show.flyer_image || item.thumbnail_image
  const imageAltText = createImageAltText(item)
  const videoShouldPlay = isMobile() || isHovered

  return (
    <div
      className={[styles.Wrapper, className].join(' ')}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link item={item} uiLocation={uiLocation}>
        <Background
          className={styles.Image}
          image={image}
          altText={imageAltText}
          video={videoShouldPlay ? item.trailer_video : null}
          autoplay
          muted
        />
        <Info item={item} />
      </Link>
    </div>
  )
}

const Info = ({item}: {item: GenericItemType}) => {
  const displayChartPosition = shouldDisplayChartPosition(item)
  return (
    <div className={styles.Info}>
      <React.Fragment>
        {displayChartPosition &&
          <IconWithText
            icon={<Bolt className={styles.BoltIcon} />}
            text={'Trending'}
            color={'#FFE704'}
          />}
        <h4 className={styles.Title}>{item.title}</h4>
        <ItemInfo item={item} />
        {item.type === genericItemTypes.article && <div>{item.summary}</div>}
        {item.recording && <BroadcastGenres recording={item.recording} />}
        {item.type === genericItemTypes.link && <p className={styles.LinkDescription} dangerouslySetInnerHTML={{__html: striptags(item.summary || item.description)}} />}
      </React.Fragment>
    </div>
  )
}
