// @flow
import React from 'react'

import Link from '../Link'
import Arrow from '../Svgs/Arrow'

import styles from './ArrowLink.less'

export default ({className, text, link, uiLocation}: {className?: string, text: string, link: string, uiLocation: string}) =>
  <Link className={[styles.Wrapper, className].join(' ')} internalLink={link} uiLocation={uiLocation}>
    {text}
    <span className={styles.Arrow}>
      <Arrow direction='right' />
    </span>
  </Link>
